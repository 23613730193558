import React from 'react';
import {FaFacebook, FaTwitter, FaInstagram, FaLinkedin} from 'react-icons/fa';
import '../footer.css';

const Footer = () => (
    <footer id="footer" className="bg-black text-white p-6">
        <div className="container mx-auto grid grid-cols-3 gap-4">
            <div>
                <h2> Telefoni</h2>
                <p>+381 60 55 11 921</p>
                <p>+381 60 31 19 513</p>
            </div>
            <div>
                <h2>Email</h2>
                <p>pergoleinc.systems@gmail.com</p>
            </div>
            <div>
                <h2>Lokacija</h2>
                <p>Takovska 46, Beograd</p>
            </div>
        </div>
        <div className="socials flex justify-center space-x-4 mt-6">
            <a href="https://instagram.com/pergoleinc_systems" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={24}/>
            </a>
        </div>
    </footer>
);

export default Footer;  // Make sure this line is present at the bottom of your Footer.js file.