import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../gallery.css';

const data = [
    {src: './images/photo1.jpg', alt: 'Image 1'},
    {src: './images/photo3.jpg', alt: 'Image 3'},
    {src: './images/photo5.jpg', alt: 'Image 5'},
    {src: './images/photo6.jpg', alt: 'Image 6'},
    {src: './images/photo7.jpg', alt: 'Image 7'},
    {src: './images/photo8.jpg', alt: 'Image 8'},
    {src: './images/photo9.jpg', alt: 'Image 9'},
    {src: './images/photo10.jpg', alt: 'Image 10'},
    {src: './images/photo11.jpg', alt: 'Image 11'},
    {src: './images/photo12.jpg', alt: 'Image 12'},
    {src: './images/photo13.jpg', alt: 'Image 13'},
    {src: './images/photo14.jpg', alt: 'Image 14'},
    {src: './images/photo15.jpg', alt: 'Image 15'},
    {src: './images/photo16.jpg', alt: 'Image 16'},
    {src: './images/photo17.jpg', alt: 'Image 17'},
    {src: './images/photo18.jpg', alt: 'Image 18'},
    {src: './images/photo19.jpg', alt: 'Image 19'},
    {src: './images/photo20.jpg', alt: 'Image 20'},
    {src: './images/photo21.jpg', alt: 'Image 21'},
    {src: './images/photo22.jpg', alt: 'Image 22'},
    {src: './images/photo23.jpg', alt: 'Image 23'},
    {src: './images/photo24.jpg', alt: 'Image 24'},
    {src: './images/photo25.jpg', alt: 'Image 25'},
    {src: './images/photo26.jpg', alt: 'Image 26'},
    {src: './images/photo27.jpg', alt: 'Image 27'},
    {src: './images/photo28.jpg', alt: 'Image 28'},
    {src: './images/photo29.jpg', alt: 'Image 29'},
    {src: './images/photo30.jpg', alt: 'Image 30'},
    {src: './images/photo31.jpg', alt: 'Image 31'},
    {src: './images/photo32.jpg', alt: 'Image 32'},
    {src: './images/photo33.jpg', alt: 'Image 33'},
    {src: './images/photo34.jpg', alt: 'Image 34'},
    {src: './images/photo35.jpg', alt: 'Image 35'},
    {src: './images/photo36.jpg', alt: 'Image 36'},
    {src: './images/photo37.jpg', alt: 'Image 37'},
    {src: './images/photo38.jpg', alt: 'Image 38'},
    {src: './images/photo39.jpg', alt: 'Image 39'},
    {src: './images/photo40.jpg', alt: 'Image 40'},
    {src: './images/photo41.jpg', alt: 'Image 41'},
    {src: './images/photo42.jpg', alt: 'Image 42'},
    {src: './images/photo43.jpg', alt: 'Image 43'},
    {src: './images/photo44.jpg', alt: 'Image 44'},
    {src: './images/photo45.jpg', alt: 'Image 45'},
    {src: './images/photo46.jpg', alt: 'Image 46'},
    {src: './images/photo47.jpg', alt: 'Image 47'},
    {src: './images/photo48.jpg', alt: 'Image 48'},
    {src: './images/photo49.jpg', alt: 'Image 49'},
    {src: './images/photo50.jpg', alt: 'Image 50'},
    {src: './images/photo51.jpg', alt: 'Image 51'},
    {src: './images/photo52.jpg', alt: 'Image 52'},
    {src: './images/photo53.jpg', alt: 'Image 53'},
    {src: './images/photo54.jpg', alt: 'Image 54'},
    {src: './images/photo55.jpg', alt: 'Image 55'},
    {src: './images/photo56.jpg', alt: 'Image 56'},
    {src: './images/photo57.jpg', alt: 'Image 57'},
    {src: './images/photo58.jpg', alt: 'Image 58'},
    {src: './images/photo59.jpg', alt: 'Image 59'},
    {src: './images/photo60.jpg', alt: 'Image 60'},
    {src: './images/photo60.jpg', alt: 'Image 61'}
];

const Gallery = () => {
    // nova logika za određivanje broja slajdova
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const slidesToShow = windowWidth <= 768 ? 1 : 3;

    const settings = {
        infinite: true,
        speed: 300,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        centerPadding: '10px', // dodajem malo centriranja i margine
        centerMode: true, // centriranje aktivirano
        autoplay: true, // podesite na true za automatsko kretanje
        autoplaySpeed: 2500, // vreme u milisekundama za automatsko kretanje (3000ms = 3 sekunde)
        arrows: false, // ovde uklanjamo navigacione strelice
    };

    // vaša prethodna logika za upravljanje klikovima na sliku
    const handleClick = (imgSrc) => {
        window.open(imgSrc, '_blank');
    };

    return (
        <section className="gallery-section">
            <div className="App">
                <h1 className="GalleryTitle">GALERIJA</h1>
                <Slider {...settings}>
                    {data.map((img, idx) => (
                        <div key={idx} className="SlideItem">
                            <img src={img.src} alt={img.alt} onClick={() => handleClick(img.src)}/>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default Gallery;