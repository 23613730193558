import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import '../informacije.css';

function Informacije() {
    const [showText, setShowText] = useState(false);

    return (
        <div>
            <div className="info-container">
                <img className="info-image" src='./images/photo1.jpg' alt='Opis slike'/>
                <ul className="info-list">
                    <li><FaCheck style={{marginRight: '10px'}}/> Ravne pergole</li>
                    <li><FaCheck style={{marginRight: '10px'}}/> Zakrivljene pergole</li>
                    <li><FaCheck style={{marginRight: '10px'}}/> Viseće pergole</li>
                    <li><FaCheck style={{marginRight: '10px'}}/> Bioklimatske pergole</li>
                    <li><FaCheck style={{marginRight: '10px'}}/> Skrin sistemi</li>
                    <li><FaCheck style={{marginRight: '10px'}}/> Stakleni sistem</li>
                </ul>
                <div className="desktop-text">
                    Pergole su najkvalitetniji savremeni sistemi zaštite od sunca, kiše, jakih vetrova, < br/>kao i drugih atmosferskih uticaja. < br/>Aluminijumski profili predstavljaju osnovu pergola.< br/>
                    Platna pergole su otporna od +70 do -30 C, otporna su na cepanja 100% < br/>što omogućava da u dugom vremenskom periodu. < br/>Pergole zadržavaju isti kvalitet.< br/>
                    Platna su troslojna i poseduju sloj za zamračivanje koja pružaju totalnu UV zaštitu i pruža < br/>izuzetnu termo izolaciju,
                    pa dolazi do redukcije toplote u prostoru ispod pergola. < br/>Imaju pokretne i rotirajuće termoizolovane aluminijumske krovne profile < br/>čija je nosivot 400kg/m^2< br/>
                    Takodje poseduje integrisanu LED rasvetu (mogućnost menjanja boje).< br/>
                    Namenjena je za sva godišnja doba, pokrivanje terasa i bašta.< br/>
                    Upotrebom staklenih sistema (uz pergolu ili bioklimatsku pergolu) < br/>postiže se najveći nivo energetske efikasnosti.< br/>
                    Specijalno dizajnirani vertikalni profili između staklenih panela pružaju potpunu zaptivenost spojeva.
                </div>
            </div>
            <div className="button-container">
                <button className="more-info-button" onClick={() => setShowText(!showText)}>
                    VISE INFORMACIJA
                </button>
            </div>
            {showText &&
                <div className="text-container">
                    Pergole su najkvalitetniji savremeni sistemi zaštite < br/> od sunca, kiše, jakih vetrova, kao i drugih < br/>atmosferskih uticaja. < br/>Aluminijumski profili predstavljaju osnovu pergola.< br/>
                    Platna pergole su otporna od +70 do -30 C, < br/>otporna su na cepanja 100% < br/>što omogućava da u dugom vremenskom periodu. < br/>Pergole zadržavaju isti kvalitet.< br/>
                    Platna su troslojna i poseduju sloj za zamračivanje < br/>koja pružaju totalnu UV zaštitu i pruža izuzetnu termo izolaciju,
                    pa dolazi do redukcije toplote u < br/>prostoru ispod pergola. < br/>Imaju pokretne i rotirajuće termoizolovane aluminijumske krovne profile < br/>čija je nosivot 400kg/m^2< br/>
                    Takodje poseduje integrisanu LED rasvetu (mogućnost menjanja boje).< br/>
                    Namenjena je za sva godišnja doba, pokrivanje terasa i bašta.< br/>
                    Upotrebom staklenih sistema < br/>(uz pergolu ili bioklimatsku pergolu) < br/>postiže se najveći nivo energetske efikasnosti.< br/>
                    Specijalno dizajnirani vertikalni profili < br/>između staklenih panela pružaju < br/>potpunu zaptivenost spojeva.
                </div>
            }
        </div>
    );
}

export default Informacije;