import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import '../navsection.css';

const Navsection = () => {
    const texts = ['Pergole Inc. Systems je firma čija je glavna delatnost izrada i instalacija pergola na objekte.', 'Pergola je pokretni krovni sistem koji Vas štiti od sunca, kiše, snega i vetra.', 'Otporana je na sve vremenske uslove!', 'Pružamo Vam najkvalitnetniju uslugu sa najboljim cenama i instalaciju u najkraćem vremenskom roku.', 'Stojimo Vam na raspolaganju 24 sata!']; /* <-- Dodano ovdje */
    const [index, setIndex] = useState(0); /* <-- Dodano ovdje */

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((current) => (current + 1) % texts.length);
        }, 8000); // sada se mijenja tekst svakih 8 sekundi
        return () => clearInterval(interval);
    }, [texts.length]);

    useEffect(() => {
        document.body.style.backgroundImage = 'none'; // Removes the body background image
    }, []);

    const handleContactClick = (event) => {
        event.preventDefault();
        document.getElementById("footer").scrollIntoView({ behavior: 'smooth' });
    };

    return (

        <section className='root'>
            <video autoPlay="autoplay" loop="loop" muted className="video">
                <source src={process.env.PUBLIC_URL + '/navsection.mp4'} type='video/mp4'/>
            </video>
            <div className='overlay'>
                <div className='div-parent'>

                    <div className='title-block'>
                        <h1 className='text-4xl md:text-7xl font-bold mb-4'>Pergole Inc. Systems</h1>
                        <p className='text-lg md:text-xl font-bold'>
                            PRUŽAMO VAM NAJKVALITETNIJU USLUGU SA NAJBOLJIM CENAMA.
                        </p>
                    </div>

                    <div className='content-block text-lg md:text-xl font-light animate-fade'>
                        <p>{texts[index]}</p>
                    </div>
                    <Link
                        activeClass="active"
                        to="footer"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={1000}
                        className="contact button-style"
                    >
                        KONTAKT
                    </Link>
                </div>
            </div>
        </section>
    )
        ;
};

export default Navsection;