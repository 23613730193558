import React from "react";
import Navsection from './components/Navsection';
import Information from './components/Information';
import Gallery from './components/Gallery';
import Footer from './components/Footer';

const MainLayout = () => {
    return (
        <div>
            <Navsection />
            <Information />
            <Gallery />
            <Footer />
        </div>
    );
}

export default MainLayout;